import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { urlHelper } from '../helpers/urlHelper';
import { Header } from '../components/Header';
import { PublicHeader } from '../components/PublicHeader';
import { Business } from './Business';
import { ConfirmResetPassword } from './ConfirmResetPassword';
import { Configuration } from './Configuration';
import { Contact } from './Contact';
import { Dashboard } from './Dashboard';
import { FAQs } from './FAQs';
import { Landing } from './Landing';
import { Links } from './Links';
import { Loading } from '../components/Loading';
import { Forbidden } from '../components/Forbidden';
import { Login } from './Login';
import { OneUseLinks } from './OneUseLinks';
import { Payments } from './Payments';
import { PlexoPayment } from './PlexoPayment';
import { Profile } from './Profile';
import { Redirect } from './Redirect';
import { Register } from './Register';
import { ResetPassword } from './ResetPassword';
import { Result } from './Result';
import { Users } from './Users';
import { Home } from './Home';
import { TermsAndConditions } from './TermsAndConditions';
import { RequestIssuers } from './RequestIssuers';
import { ManageClients } from './ManageClients';
import { Banks } from './Banks';
import Cookies from 'universal-cookie';
import { RootState } from '../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../store/config/ActionTypes';
import { getAccount } from '../store/action_creators/auth.actions';
import { connect, ConnectedProps } from 'react-redux';
import { ConfirmEmail } from '../screens/ConfirmEmail';
import { UserTypeEnum } from '../store/config/enums';
import { CommerceDialog } from '../components/dialogs/CommerceDialog';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getAccount: () => dispatch(getAccount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Main({ auth, getAccount }: PropsFromRedux) {
  const cookies = new Cookies();
  const history = useHistory();
  const [isPrivatePage, setIsPrivatePage] = useState<boolean>(
    urlHelper.isPrivatePage(history.location.pathname),
  );
  const [isHome, setIsHome] = useState<boolean>(history.location.pathname === '/');
  const [noCommercesYet, setNoCommercesYet] = useState<boolean>(
    isPrivatePage &&
      history.location.pathname !== '/register' &&
      history.location.pathname !== '/request-issuers' &&
      auth.account !== null &&
      auth.account?.business &&
      !auth.account?.business.hasCommerces,
  );
  const hasToken = cookies.get('token') !== undefined;
  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;

  useEffect(() => {
    if (hasToken) {
      if (!auth.loggedIn && !auth.loggingIn && auth.logInErrorMessage === null && isPrivatePage) {
        getAccount();
      }

      if (history.location.pathname === '/login') {
        setIsPrivatePage(true);
        if (auth.account && auth.account.business === null && !isSuperAdmin) {
          history.push('/register?step=2');
        } else {
          setNoCommercesYet(
            auth.account !== null && auth.account?.business && !auth.account?.business.hasCommerces,
          );
          isSuperAdmin ? history.push('/banks') : history.push('/dashboard');
        }
      }
    }
    if (!hasToken && isPrivatePage) {
      history.push('/login');
      setIsPrivatePage(false);
    }
  }, [
    isPrivatePage,
    auth.loggedIn,
    auth.loggingIn,
    getAccount,
    history,
    hasToken,
    auth.logInErrorMessage,
    auth.account,
    isSuperAdmin,
  ]);

  useEffect(() => {
    if (auth.account && auth.account.business) {
      setNoCommercesYet(
        isPrivatePage &&
          history.location.pathname !== '/register' &&
          history.location.pathname !== '/request-issuers' &&
          !auth.account.business.hasCommerces,
      );
    }
  }, [auth.account, isPrivatePage, history.location.pathname, setNoCommercesYet]);

  useEffect(() => {
    return history.listen((location) => {
      const path = location.pathname;
      const isPrivate = urlHelper.isPrivatePage(path);

      setIsHome(path === '/');
      setIsPrivatePage(isPrivate);
      setNoCommercesYet(
        isPrivate &&
          path !== '/register' &&
          path !== '/request-issuers' &&
          auth.account !== null &&
          auth.account?.business &&
          !auth.account?.business.hasCommerces,
      );

      if (
        isPrivate &&
        path !== '/register' &&
        auth.account &&
        auth.account.business === null &&
        !isSuperAdmin
      ) {
        history.push('/register?step=2');
      }

      if (path === '/login' && hasToken) {
        isSuperAdmin ? history.push('/banks') : history.push('/dashboard');
      }
    });
  }, [history, auth.account, isPrivatePage, hasToken, isSuperAdmin]);

  const closeCommercesDialog = () => {
    console.log('cant close');
  };

  const isLoadingBusiness = hasToken && !auth.loggedIn && isPrivatePage && auth.account?.business === null;

  const isRedirectPage = history.location.pathname.startsWith('/redirect');

  return (
    <div className="main">
      {isPrivatePage ? <Header /> : !isRedirectPage && <PublicHeader isHome={isHome} />}
      <div className={isHome ? 'home' : 'content'}>
        {isLoadingBusiness ? (
          <Loading />
        ) : noCommercesYet ? (
          <CommerceDialog open={true} setOpen={closeCommercesDialog} commerce={null} firstCommerce />
        ) : (
          <Switch>
            <Route
              path="/business"
              component={
                auth.account ? (auth.account.type === UserTypeEnum.OWNER ? Business : Forbidden) : Loading
              }
            />
            <Route path="/confirm-reset" component={ConfirmResetPassword} />
            <Route path="/configuration" component={Configuration} />
            <Route path="/contact" component={Contact} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/faqs" component={FAQs} />
            <Route path="/user-faqs" component={FAQs} />
            <Route path="/links" component={Links} />
            <Route path="/loading" component={Loading} />
            <Route path="/login" component={Login} />
            <Route path="/one-use-links" component={OneUseLinks} />
            <Route path="/payments" component={Payments} />
            <Route path="/plexo-payment" component={PlexoPayment} />
            <Route path="/profile" component={Profile} />
            <Route path="/redirect" component={Redirect} />
            <Route path="/register" component={Register} />
            <Route path="/result" component={Result} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/request-issuers" component={RequestIssuers} />
            <Route path="/user-contact" component={Contact} />
            <Route
              path="/manage-clients"
              component={auth.account ? (isSuperAdmin ? ManageClients : Forbidden) : Loading}
            />
            <Route path="/banks" component={auth.account ? (isSuperAdmin ? Banks : Forbidden) : Loading} />
            <Route
              path="/users"
              component={
                auth.account !== null
                  ? auth.account.type === UserTypeEnum.OWNER
                    ? Users
                    : Forbidden
                  : Loading
              }
            />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/verify" component={ConfirmEmail} />
            <Route path="/" exact={true} component={Home} />
            <Route path="/:companyId?/:currency?/:amount?/:linkId?" exact={false} component={Landing} />
          </Switch>
        )}
      </div>
    </div>
  );
}

export default connector(Main);
