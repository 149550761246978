import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@material-ui/core';
import { LinkCard } from '../../components/LinkCard';
import { ScreenTitle } from '../../components/ScreenTitle';
import { FiltersAccordion } from '../../components/FiltersAccordion';
import { DeleteLinkRequest, Link, Filters, RootState } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { deleteLink, fetchLinks, loadLinksFilters } from '../../store/action_creators/links.actions';
import { connect, ConnectedProps } from 'react-redux';
import { LinkDetailDialog } from '../../components/dialogs/LinkDetailDialog';
import { LinkTypeEnum, Status } from '../../store/config/enums';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { EmptyMessage } from '../../components/EmptyMessage';
import { Loading } from '../../components/Loading';
import { ErrorMessage } from '../../components/ErrorMessage';
import { initialState as initialLinks } from '../../store/reducers/links';
import InfiniteScroll from 'react-infinite-scroll-component';
import values from '../../data/values';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => {
  return {
    loadLinksFilters: (filters: Filters, businessId: number, type: LinkTypeEnum) =>
      dispatch(loadLinksFilters(filters, 1, 10, businessId, type)),
    fetchLinks: (
      filters: Filters,
      page: number,
      count: number,
      businessId: number,
      type: LinkTypeEnum,
      reset: boolean,
    ) => dispatch(fetchLinks(filters, page, count, businessId, type, reset)),
    deleteLink: (deleteRequest: DeleteLinkRequest) => dispatch(deleteLink(deleteRequest)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function OneUseLinks({ auth, links, loadLinksFilters, fetchLinks, deleteLink }: PropsFromRedux) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingLink, setDeletingLink] = useState<boolean>(false);
  const [linkToDelete, setLinkToDelete] = useState<Link | null>(null);

  const search = () => {
    if (searchTerm.length > 3 || searchTerm.length === 0) {
      const filters = { ...links.linksFilters, term: searchTerm };
      loadLinksFilters(filters, auth.account?.business.id!, LinkTypeEnum.ONETIME);
    }
  };

  const loadFilters = (filters: Filters) => {
    if (auth.account) {
      loadLinksFilters(filters, auth.account?.business.id!, LinkTypeEnum.ONETIME);
    }
  };

  useEffect(() => {
    if (
      auth.account &&
      !links.loadingLinks &&
      (links.links === null || links.linksType !== LinkTypeEnum.ONETIME) &&
      (links.linksErrorMessage === null || links.linksType !== LinkTypeEnum.ONETIME)
    ) {
      fetchLinks(
        initialLinks.linksFilters,
        1,
        values.linksPageLimit,
        auth.account?.business.id!,
        LinkTypeEnum.ONETIME,
        true,
      );
    }
    if (links.links) setAmount(links.links.length);
  }, [
    links.loadingLinks,
    links.links,
    links.linksType,
    links.linksErrorMessage,
    fetchLinks,
    links.linksFilters,
    auth.account,
  ]);

  const beginDeleteLink = (link: Link) => {
    setDeleteOpen(true);
    setLinkToDelete(link);
  };

  const confirmDeleteLink = () => {
    setDeletingLink(true);

    if (auth.account?.business.id && linkToDelete) {
      deleteLink({ businessId: auth.account.business.id.toString(), linkId: linkToDelete.id });
    }
  };

  const closeDeleteSnack = () => {
    setDeletingLink(false);

    if (links.deleteLinkSuccess) setDeleteOpen(false);
  };

  const loadMore = () => {
    fetchLinks(
      links.linksFilters,
      links.currentPage + 1,
      values.linksPageLimit,
      auth.account?.business.id!,
      LinkTypeEnum.ONETIME,
      false,
    );
  };

  return (
    <div className="screen-container links">
      <div className="screen-header">
        <div className="screen-title">
          <ScreenTitle
            title="Links de 1 uso"
            elementsAmount={amount}
            tooltip="Permite crear un link de pago para solo un usuario que podrá ser usado una única vez."
          />
        </div>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)} disableElevation>
          Crear link
        </Button>
      </div>
      <Divider className="screen-divider" />
      <FiltersAccordion
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={links.linksFilters}
        setFilters={loadFilters}
        triggerSearch={search}
      />
      {links.links !== null ? (
        links.links.length > 0 ? (
          <InfiniteScroll
            className="infinite"
            hasMore={links.currentPage < links.pageCount}
            next={loadMore}
            dataLength={links.links ? links.links.length : 0}
            loader={
              links.loadingLinks && (
                <div className="loading-div">
                  <Loading />
                </div>
              )
            }
          >
            <Grid container spacing={2} className="links-list">
              {links.links.map((link) => (
                <Grid item xs={12} sm={6} key={link.id}>
                  <LinkCard link={link} setLinkToDelete={beginDeleteLink} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        ) : (
          <EmptyMessage message="No se encontraron links para los filtros aplicados" />
        )
      ) : links.loadingLinks ? (
        <Loading />
      ) : (
        links.linksErrorMessage && <ErrorMessage message="Ha ocurrido un error al obtener los links" />
      )}
      <LinkDetailDialog open={open} setOpen={setOpen} link={null} linkType={LinkTypeEnum.ONETIME} />
      <DeleteDialog
        message="¿Está seguro de que desea eliminar el link?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteAction={confirmDeleteLink}
        deleteDisabled={deletingLink}
      />
      <CustomSnackbar
        open={deletingLink && (links.deleteLinkSuccess || links.deleteLinkErrorMessage !== null)}
        message={
          links.deleteLinkSuccess ? 'Se borró el link correctamente' : 'Ocurrió un error al borrar el link'
        }
        handleClose={closeDeleteSnack}
        type={links.deleteLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(OneUseLinks);
