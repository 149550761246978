import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Commerce,
  CreateCommerceRequest,
  RootState,
  UpdateCommerceRequest,
} from '../../../store/config/types';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { FormTextField } from '../../forms/FormTextField';
import { FormSelectField } from '../../forms/FormSelectField';
import schemas from '../../../data/schemas';
import {
  fetchIssuers,
  createCommerce,
  updateCommerce,
  createBusinessCommerce,
  updateBusinessCommerce,
} from '../../../store/action_creators/commerces.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { ThunkDispatch } from 'redux-thunk';
import { connect, ConnectedProps } from 'react-redux';
import { CustomSnackbar } from '../../CustomSnackbar';
import { Status, UserTypeEnum } from '../../../store/config/enums';
import { Loading } from '../../Loading';
import LogoutDialog from '../../Header/LogoutDialog';
import PlexoFields from './PlexoFields';
import InstallmentsField from './InstallmentsField';
import BanksSelector from './BanksSelector';
import { Client } from '../../../store/config/types/clients.types';

interface CommerceDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  commerce: Commerce | null;
  firstCommerce?: boolean;
  client?: Client;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  commerces: state.commerces,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchIssuers: () => dispatch(fetchIssuers()),
  createCommerce: (createCommerceRequest: CreateCommerceRequest) =>
    dispatch(createCommerce(createCommerceRequest)),
  createBusinessCommerce: (createCommerceRequest: CreateCommerceRequest, businessId: number) =>
    dispatch(createBusinessCommerce(createCommerceRequest, businessId)),
  updateCommerce: (updateCommerceRequest: UpdateCommerceRequest) =>
    dispatch(updateCommerce(updateCommerceRequest)),
  updateBusinessCommerce: (updateCommerceRequest: UpdateCommerceRequest, businessId: number) =>
    dispatch(updateBusinessCommerce(updateCommerceRequest, businessId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & CommerceDialogProps;

interface Values {
  issuer: string;
  acquirer: string;
  installments: number;
  softDescriptor: string;
  accountNumber: string;
  commerceNumber: string;
  terminalNumber: string;
}

function CommerceDialog({
  auth,
  commerces,
  clients,
  fetchIssuers,
  createCommerce,
  createBusinessCommerce,
  updateCommerce,
  updateBusinessCommerce,
  open,
  setOpen,
  commerce,
  firstCommerce = false,
  client,
}: PropsType) {
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);
  const [creatingCommerce, setCreatingCommerce] = useState<boolean>(false);
  const [updatingCommerce, setUpdatingCommerce] = useState<boolean>(false);
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const [selectedInstallments, setSelectedInstallments] = useState<number[]>(
    commerce ? commerce.installments : [],
  );

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!commerces.loadingIssuers && commerces.issuers === null && commerces.issuersErrorMessage === null) {
      fetchIssuers();
    }
  }, [commerces.loadingIssuers, commerces.issuers, commerces.issuersErrorMessage, fetchIssuers]);

  const submitCommerce = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      const selectedIssuer = commerces.issuers && commerces.issuers.filter((i) => i.id === values.issuer)[0];
      const noInstallmentsIssuer = selectedIssuer?.issuerId === 13 || selectedIssuer?.issuerId === 21;

      if (auth.account.type === UserTypeEnum.SUPERADMIN) {
        if (commerce) {
          setUpdatingCommerce(true);
          const updateCommerceRequest: UpdateCommerceRequest = {
            businessId: client!.id,
            commerceId: Number(commerce.id),
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            maxInstallments: values.installments ?? 1,
            softDescriptor: values.softDescriptor,
            acquirer: values.acquirer,
            accountNumber: values.accountNumber,
          };
          updateBusinessCommerce(updateCommerceRequest, client!.id);
        } else {
          setCreatingCommerce(true);

          const createCommerceRequest: CreateCommerceRequest = {
            businessId: client!.id,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor,
            accountNumber: values.accountNumber,
            commerceNumber: values.commerceNumber,
            terminalNumber: values.terminalNumber,
            issuerId: selectedIssuer!.issuerId,
            banks: selectedBanks,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer : null,
          };
          createBusinessCommerce(createCommerceRequest, client!.id);
        }
      } else {
        if (commerce) {
          setUpdatingCommerce(true);

          const updateCommerceRequest: UpdateCommerceRequest = {
            businessId: auth.account.business.id!,
            commerceId: Number(commerce.id),
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor,
            accountNumber: values.accountNumber,
            acquirer: values.acquirer,
          };

          updateCommerce(updateCommerceRequest);
        } else {
          setCreatingCommerce(true);

          const createCommerceRequest: CreateCommerceRequest = {
            businessId: auth.account.business.id!,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor,
            accountNumber: values.accountNumber,
            commerceNumber: values.commerceNumber.trim(),
            terminalNumber: values.terminalNumber.trim(),
            issuerId: selectedIssuer!.issuerId,
            banks: selectedBanks,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer : null,
          };

          setSelectedBanks([]);
          createCommerce(createCommerceRequest);
        }
      }
    }
  };

  const closeSnack = () => {
    if (commerce) {
      setUpdatingCommerce(false);
    } else {
      setCreatingCommerce(false);
    }

    if (
      commerces.createCommerceSuccess ||
      commerces.updateCommerceSuccess ||
      clients.creatingCommercesSuccess ||
      clients.updatingCommercesSuccess
    )
      closeDialog();
  };

  const openLogout = () => {
    setLogoutOpen(true);
  };

  const issuersList =
    commerces.issuers &&
    commerces.issuers.map((i) => {
      const existsCommerce =
        commerces.commerces &&
        commerces.commerces.filter((c) => c.issuer && c.issuer.idPlexo.toString() === i.id).length > 0;

      return {
        id: i.id,
        value: i.issuer,
        disabled: commerce ? false : existsCommerce,
        paymentProcessors: i.paymentProcessors,
      };
    });

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <Typography variant="h4">
          {firstCommerce
            ? 'Agregar primer comercio'
            : commerce
            ? 'Editar comercio'
            : 'Agregar nuevo comercio'}
        </Typography>
        <CloseIcon fontSize="default" className="icon" onClick={firstCommerce ? openLogout : closeDialog} />
      </DialogTitle>
      {commerces.issuers ? (
        <Formik
          initialValues={{
            issuer:
              commerce && commerce.issuer
                ? commerce.issuer?.idPlexo.toString()
                : issuersList
                ? issuersList.filter((il) => !il.disabled)[0].id
                : '4',
            acquirer: commerce && commerce.paymentProcessor ? commerce.paymentProcessor.acquirer : '',
            installments: commerce ? commerce.maxInstallments : 1,
            softDescriptor: commerce ? commerce.softDescriptor : '',
            accountNumber: commerce ? commerce.accountNumber : '',
            commerceNumber: commerce ? commerce.number : '',
            terminalNumber: commerce ? commerce.terminalNumber : '',
          }}
          validationSchema={schemas.CommerceSchema}
          onSubmit={submitCommerce}
        >
          {({ values }) => {
            const selectedIssuer =
              commerces.issuers && commerces.issuers.filter((i) => i.id === values.issuer)[0];

            const selectedAcquirer = selectedIssuer?.paymentProcessors
              ? selectedIssuer?.paymentProcessors.filter((pp) => pp.acquirer === values.acquirer)[0]
              : '';

            const fields = selectedAcquirer
              ? selectedAcquirer.fields
              : !selectedIssuer || !selectedIssuer.paymentProcessors
              ? selectedIssuer?.fields
              : commerce
              ? selectedIssuer?.fields
              : [];

            const noInstallmentsIssuer = selectedIssuer?.issuerId === 13 || selectedIssuer?.issuerId === 21;

            return (
              <Form className="form commerce-form">
                <DialogContent classes={{ root: 'dialog-content' }}>
                  {firstCommerce && (
                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                      Antes de continuar utilizando la herramienta deberás ingresar un primer código de
                      comercio
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="issuer"
                        component={FormSelectField}
                        disabled={commerce !== null}
                        label="Medio de pago"
                        options={issuersList}
                      />
                    </Grid>
                    {selectedIssuer?.paymentProcessors && (
                      <Grid item xs={12}>
                        <Field
                          name="acquirer"
                          component={FormSelectField}
                          disabled={commerce !== null}
                          label="Adquirente"
                          options={
                            selectedIssuer?.paymentProcessors
                              ? selectedIssuer?.paymentProcessors.map((pp) => {
                                  return {
                                    id: pp.acquirer,
                                    value: pp.acquirer,
                                    disabled: false,
                                  };
                                })
                              : []
                          }
                        />
                      </Grid>
                    )}
                    {!noInstallmentsIssuer && (
                      <InstallmentsField
                        commerce={commerce}
                        selectedInstallments={selectedInstallments}
                        setSelectedInstallments={setSelectedInstallments}
                      />
                    )}
                    <Grid item xs={12}>
                      <Field
                        name="softDescriptor"
                        component={FormTextField}
                        type="text"
                        placeholder="Soft descriptor"
                      />
                    </Grid>
                    <Grid item xs={12} className="soft-descriptor">
                      El "Soft descriptor" es el texto que se le muestra al cliente en el estado de cuenta de
                      la tarjeta
                    </Grid>
                    <PlexoFields fields={fields} commerce={commerce} />
                    <BanksSelector
                      selectedIssuer={selectedIssuer}
                      selectedBanks={
                        commerce?.banks
                          ? commerce?.banks.map((b) => {
                              return b.id;
                            })
                          : selectedBanks
                      }
                      setSelectedBanks={setSelectedBanks}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions classes={{ root: 'dialog-actions' }}>
                  <Button type="submit" color="primary" variant="contained" fullWidth>
                    {commerce ? 'Guardar cambios' : 'Agregar comercio'}
                  </Button>
                </DialogActions>
                <CustomSnackbar
                  open={
                    creatingCommerce &&
                    (commerces.createCommerceSuccess ||
                      commerces.createCommerceErrorMessage !== null ||
                      clients.creatingCommercesSuccess ||
                      clients.creatingCommercesErrorMessage !== null)
                  }
                  message={
                    commerces.createCommerceSuccess || clients.creatingCommercesSuccess
                      ? 'Se creó el comercio correctamente'
                      : auth.account?.type === UserTypeEnum.SUPERADMIN
                      ? clients.creatingCommercesErrorMessage!
                      : commerces.createCommerceErrorMessage!
                  }
                  handleClose={closeSnack}
                  type={
                    commerces.createCommerceSuccess || clients.creatingCommercesSuccess
                      ? Status.SUCCESS
                      : Status.ERROR
                  }
                />
                <CustomSnackbar
                  open={
                    updatingCommerce &&
                    (commerces.updateCommerceSuccess ||
                      commerces.updateCommerceErrorMessage !== null ||
                      clients.updatingCommercesSuccess ||
                      clients.updatingCommercesErrorMessage !== null)
                  }
                  message={
                    commerces.updateCommerceSuccess || clients.updatingCommercesSuccess
                      ? 'Se actualizó el comercio correctamente'
                      : auth.account?.type === UserTypeEnum.SUPERADMIN
                      ? clients.updatingCommercesErrorMessage!
                      : commerces.updateCommerceErrorMessage!
                  }
                  handleClose={closeSnack}
                  type={
                    commerces.updateCommerceSuccess || clients.updatingCommercesSuccess
                      ? Status.SUCCESS
                      : Status.ERROR
                  }
                />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Loading />
      )}
      <LogoutDialog open={logoutOpen} setOpen={setLogoutOpen} />
    </Dialog>
  );
}

export default connector(CommerceDialog);
