import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#006ed2',
      light: '#E8F0F8',
    },
    secondary: {
      main: '#0cd782',
    },
    error: {
      main: '#f11930',
      light: '#f9ecec',
      dark: '#c54343',
    },
    warning: {
      main: '#ffc400',
      light: '#fff9e5',
      dark: '#ffc400',
    },
    success: {
      main: '#1fd286',
      light: '#edf4ed',
      dark: '#1fd286',
    },
    background: {
      default: '#f6f6f6',
    },
  },
  typography: {
    fontFamily: `"Rubik", sans-serif`,
    h1: {
      fontSize: '3.75rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.125rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
    subtitle2: {
      color: '#5D5D5D',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 6,
        textTransform: 'none',
        padding: '8px 16px',
        fontSize: 16,
        fontWeight: 400,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(112, 112, 112, 0.9)',
      },
    },
    MuiPaper: {
      elevation2: {
        padding: '2.6rem 2rem 2rem',
        boxShadow: '0px 3px 6px #00000029',
        width: '100%',
        marginTop: '1rem',
      },
      elevation3: {
        padding: '0.8rem 1.6rem',
        boxShadow: '0px 3px 6px #00000029',
        width: '100%',
        marginTop: '1rem',
      },
    },
  },
});
