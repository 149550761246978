import React, { useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { FormTextField } from '../FormTextField';
import { Button, Typography, Link, Checkbox, makeStyles, createStyles } from '@material-ui/core';
import { RootState, UserRequest } from '../../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../../store/config/ActionTypes';
import { registerUser } from '../../../store/action_creators/auth.actions';
import { ConnectedProps, connect } from 'react-redux';
import schemas from '../../../data/schemas';
import { CustomSnackbar } from '../../CustomSnackbar';
import { authService } from '../../../services/auth.service';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: '0.2rem',
      paddingLeft: 0,
    },
  }),
);

interface UserValues {
  names: string;
  surnames: string;
  telephoneNo: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface UserFormProps {
  setEmail?: Dispatch<SetStateAction<string>>;
}

interface LooseObject {
  [key: string]: any;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  registerUser: (user: UserRequest) => dispatch(registerUser(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & UserFormProps;

function UserForm({ auth, registerUser, setEmail }: PropsType) {
  const history = useHistory();
  const classes = useStyles();

  const [creating, setCreating] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [validatedEmail, setValidatedEmail] = useState<string>('');
  const [validating, setValidating] = useState<boolean>(false);
  const [mailHasError, setMailHasError] = useState<boolean>(false);

  const submitUser = (values: UserValues) => {
    const user: UserRequest = {
      names: values.names,
      surnames: values.surnames,
      telephoneNo: values.telephoneNo,
      email: values.email,
      password: values.password,
    };

    if (setEmail) {
      setEmail(values.email);
    }

    setCreating(true);
    registerUser(user);
  };

  const closeSnack = () => {
    setCreating(false);

    if (auth.changeCredentialsSuccess) {
      history.push('/dashboard');
    }
  };

  const validEmail = (email: string) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validate = async (values: UserValues) => {
    let mailValue = values.email;
    let errors: LooseObject = {};

    if (mailValue !== '' && validEmail(mailValue) && mailValue !== validatedEmail && !validating) {
      setValidating(true);
      setValidatedEmail(mailValue);

      await authService.checkEmailAvailability(mailValue).then(
        (response) => {
          setValidating(false);
          setMailHasError(false);
        },
        (error) => {
          setValidating(false);
          setMailHasError(true);
          errors.email = 'El email ya existe en la plataforma';
          return errors;
        },
      );
    } else if (mailHasError) {
      errors.email = 'El email ya existe en la plataforma';
      return errors;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{
          names: '',
          surnames: '',
          telephoneNo: '',
          email: '',
          password: '',
          repeatPassword: '',
        }}
        onSubmit={submitUser}
        validate={validate}
        validateOnChange={false}
        validationSchema={schemas.RegisterUserSchema}
      >
        <Form className="form">
          <Field
            className="row-field"
            name="names"
            component={FormTextField}
            type="text"
            placeholder={'Nombres'}
          />
          <Field
            className="row-field"
            name="surnames"
            component={FormTextField}
            type="text"
            placeholder={'Apellidos'}
          />
          <Field
            className="row-field"
            name="telephoneNo"
            component={FormTextField}
            type="text"
            placeholder={'Teléfono'}
          />
          <Field
            className="row-field"
            name="email"
            component={FormTextField}
            type="text"
            placeholder="Email"
          />
          <Field
            className="row-field"
            name="password"
            component={FormTextField}
            type="password"
            placeholder={'Contraseña'}
          />
          <div className="row-field texts-row">
            <Typography variant="body2">
              La contraseña debe tener al menos una mayúscula, una minúscula y un número.
            </Typography>
          </div>
          <Field
            className="row-field"
            name="repeatPassword"
            component={FormTextField}
            type="password"
            placeholder={`Repetir contraseña`}
          />
          <div className="form-row">
            <div className="texts-row">
              <Checkbox
                classes={{ root: classes.checkbox }}
                checked={checked}
                onChange={() => setChecked(!checked)}
                color="secondary"
              />
              Al registrarte, aceptas los{' '}
              <Link href={`${window.location.origin}/terms-and-conditions`} target="_blank">
                términos y condiciones
              </Link>
              .
            </div>
            <span className="row-field" />
            <div className="button-row-field">
              <Button disabled={!checked} type="submit" color="primary" variant="contained" disableElevation>
                {'Verificar email'}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
      <CustomSnackbar
        open={creating && (auth.registerUserSuccess || auth.registerUserErrorMessage !== null)}
        message={
          auth.registerUserSuccess ? 'Se creó el usuario correctamente' : auth.registerUserErrorMessage!
        }
        handleClose={closeSnack}
        type={auth.registerUserSuccess ? 0 : 1}
      />
    </>
  );
}

export default connector(UserForm);
