import {
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { LinkTypeEnum, Status } from '../../../store/config/enums';
import { CreateLinkRequest, Link, RootState, ShareLinkRequest } from '../../../store/config/types';
import CloseIcon from '@material-ui/icons/Close';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { numberHelper } from '../../../helpers/numberHelper';
import { Form, Formik, Field } from 'formik';
import { FormTextField } from '../../forms/FormTextField';
import { ShareByEmail } from '../../Icons/ShareByEmail';
import { ShareByWhatsapp } from '../../Icons/ShareByWhatsapp';
import { ShareOptions } from '../ShareOptions';
import { ShareByWhatsAppDialog } from '../ShareByWhatsAppDialog';
import { ThunkDispatch } from 'redux-thunk';
import { createLink, shareLink } from '../../../store/action_creators/links.actions';
import { connect, ConnectedProps } from 'react-redux';
import { AppActions } from '../../../store/config/ActionTypes';
import { CustomSnackbar } from '../../CustomSnackbar';
import schemas from '../../../data/schemas';
import { QrCodeDialog } from '../QRCodeDialog';
import { ShareByQr } from '../../Icons/ShareByQr';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    valueFieldsContainer: {
      display: 'flex',
    },
    currencySelect: {
      width: '40%',
      marginRight: '1rem',
    },
    valueField: {
      fontSize: '1.5rem',
    },
    title: {
      display: 'flex',
      alignItems: 'baseline',
      '& svg': {
        color: theme.palette.secondary.main,
        marginRight: '0.5rem',
      },
    },
    linkContainer: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      marginTop: '2rem',
      '& button': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    linkInfo: {
      margin: '1rem 0',
    },
    shareBoxesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '1rem 0',
      gap: '1rem',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    whatsapp: {
      backgroundColor: '#0CD782',
      color: '#FFFFFF',
      padding: '0.4rem 1rem',
      '& .MuiButton-label': {
        display: 'flex',
        gap: 8,
      },
      '&:hover': {
        backgroundColor: '#0AB16B',
      },
    },
    email: {
      backgroundColor: '#7070BF',
      color: '#FFFFFF',
      padding: '0.4rem 1rem',
      '& .MuiButton-label': {
        display: 'flex',
        gap: 8,
      },
      '&:hover': {
        backgroundColor: '#5F5FA7',
      },
    },
    qr: {
      backgroundColor: '#277ECC',
      color: '#FFFFFF',
      padding: '0.4rem 1rem',
      '& .MuiButton-label': {
        display: 'flex',
        gap: 8,
      },
      '&:hover': {
        backgroundColor: '#226DB1',
      },
    },
    formRow: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& button': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
          margin: '1rem 0',
        },
      },
    },
    linkUrl: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '0.9rem',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    divider: {
      margin: '1rem 0',
    },
    shareTitle: {
      textAlign: 'center',
      color: theme.palette.text.disabled,
    },
    qrIcon: {
      color: '#fff',
      padding: '2px',
      borderRadius: '2px',
      marginBottom: '0px!important',
    },
    qrIconContainer: {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      backgroundColor: '#0091ea',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '1rem',
    },
  }),
);

export interface ShareLinkDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  link: Link;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  shareLink: (shareRequest: ShareLinkRequest) => dispatch(shareLink(shareRequest)),
  createLink: (createRequest: CreateLinkRequest) => dispatch(createLink(createRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & ShareLinkDialogProps;

function ShareLinkDialog({ auth, links, shareLink, createLink, open, setOpen, link }: PropsType) {
  const classes = useStyles();
  const [openEmailOptions, setOpenEmailOptions] = useState<boolean>(false);
  const [openWhatsAppOptions, setOpenWhatsAppOptions] = useState<boolean>(false);
  const [openQrDialog, setOpenQrDialog] = useState<boolean>(false);
  const [copyingLink, setCopyingLink] = useState<boolean>(false);
  const [sharingLink, setSharingLink] = useState<boolean>(false);
  const [creatingOneUseLink, setCreatingOneUseLink] = useState<boolean>(false);
  const [newLink, setNewLink] = useState<Link | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const closeDialog = () => {
    setOpen(false);
    setNewLink(null);
  };

  const copyToClipboard = () => {
    setCopyingLink(true);
    navigator.clipboard.writeText(link.url ? link.url : '');
  };

  const shareEmail = (email: string) => {
    shareLink({ linkId: link.id, email });
  };

  const createOneUseLink = (values: { reference: string }) => {
    setCreatingOneUseLink(true);
    const request: CreateLinkRequest = {
      businessId: auth.account?.business.id !== undefined ? auth.account?.business.id! : 0,
      amount: link.amount,
      description: link.description ?? '',
      reference: values.reference,
      userId: auth.account ? auth.account.userId : '',
      linkType: LinkTypeEnum.ONETIME,
      validUntil: null,
    };
    createLink(request);
  };

  const closeSnack = () => {
    if (sharingLink) {
      setSharingLink(false);
    } else if (creatingOneUseLink) {
      setCreatingOneUseLink(false);
    } else if (copyingLink) {
      setCopyingLink(false);
    }
  };

  useEffect(() => {
    if (creatingOneUseLink) {
      setNewLink(links.latestLink);
    }
  }, [links.latestLink, creatingOneUseLink]);

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm" className="dialog">
      <DialogTitle classes={{ root: 'dialog-title' }}>
        <div />
        <div className={classes.title}>
          <Typography variant="h4" component="p">{`Compartir link ${
            newLink || link.linkType === LinkTypeEnum.ONETIME
              ? 'de 1 uso'
              : link.linkType === LinkTypeEnum.PERMANENT
              ? 'multiuso'
              : ''
          }`}</Typography>
        </div>
        <CloseIcon fontSize="default" className="icon" onClick={closeDialog} />
      </DialogTitle>
      <DialogContent classes={{ root: 'dialog-content' }}>
        <div className={classes.linkContainer}>
          <Typography className={classes.linkUrl}>{newLink ? newLink.url : link?.url}</Typography>
          <Button variant="contained" color="primary" onClick={copyToClipboard} disableElevation>
            {`Copiar${isMobile ? ' link al portapapeles' : ''}`}
          </Button>
        </div>
        <Typography
          variant="h5"
          component="p"
          className={classes.linkInfo}
        >{`${currencyHelper.getCurrencyToken(
          newLink ? newLink.amount.currency : link?.amount.currency,
        )} ${numberHelper.getNumberWithDots(newLink ? newLink.amount.value : link?.amount.value)} ${
          newLink ? newLink.description : link && link.description ? link.description : ''
        }`}</Typography>

        {newLink === null && link.linkType === LinkTypeEnum.PERMANENT && (
          <Formik
            initialValues={{
              reference: link?.reference ? link.reference : '',
            }}
            onSubmit={createOneUseLink}
            validationSchema={schemas.AddReferenceSchema}
          >
            <Form className="form">
              <div className={classes.formRow}>
                <Field
                  name="reference"
                  component={FormTextField}
                  type="text"
                  placeholder="Referencia o destinatario"
                  margin="none"
                />
                <Button type="submit" variant="outlined" color="primary" disableElevation>
                  Guardar
                </Button>
              </div>
              <Typography variant="subtitle2" style={{ marginTop: '1rem' }}>
                Al agregar referencia o destinatario, se creará un link de 1 uso a partir de este link
                multiuso.
              </Typography>
            </Form>
          </Formik>
        )}
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.shareTitle}>
          Compartir por
        </Typography>
        <div className={classes.shareBoxesContainer}>
          <Button className={classes.whatsapp} onClick={() => setOpenWhatsAppOptions(true)}>
            <ShareByWhatsapp />
            Whatsapp
          </Button>
          {link.url && (
            <Button className={classes.qr} onClick={() => setOpenQrDialog(true)}>
              <ShareByQr />
              Código QR
            </Button>
          )}
          <Button className={classes.email} onClick={() => setOpenEmailOptions(true)}>
            <ShareByEmail />
            Email
          </Button>
        </div>
      </DialogContent>
      <ShareOptions open={openEmailOptions} setOpen={setOpenEmailOptions} share={shareEmail} />
      <ShareByWhatsAppDialog open={openWhatsAppOptions} setOpen={setOpenWhatsAppOptions} url={link.url!} />
      <QrCodeDialog
        open={openQrDialog}
        setOpen={setOpenQrDialog}
        url={link.url!}
        description={link.description!}
        price={link.amount}
      />
      <CustomSnackbar
        open={creatingOneUseLink && (links.createLinkSuccess || links.createLinkErrorMessage !== null)}
        message={
          links.createLinkSuccess
            ? 'Se creó un nuevo link de 1 uso.'
            : 'Ocurrió un error al crear un nuevo link de 1 uso.'
        }
        handleClose={closeSnack}
        type={links.createLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={copyingLink}
        message="Link copiado al portapapeles."
        handleClose={closeSnack}
        type={Status.SUCCESS}
      />
    </Dialog>
  );
}

export default connector(ShareLinkDialog);
