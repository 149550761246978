import React from 'react';
import { createStyles, Divider, makeStyles, Typography } from '@material-ui/core';
import { ScreenTitle } from '../../components/ScreenTitle';

const useStyles = makeStyles(() =>
  createStyles({
    paragraph: {
      margin: '1rem 0',
    },
    item: {
      fontWeight: 'bold',
    },
    title: {
      margin: '1.5rem 0',
      textAlign: 'center',
    },
  }),
);

function TermsAndConditions() {
  const classes = useStyles();
  return (
    <>
      <ScreenTitle title="Términos y condiciones" />
      <Divider className="screen-divider" />
      <Typography className={classes.title} variant="h4" component="h2">
        PLEXO LINKS
      </Typography>
      <Typography variant="h5" component="h2">
        Primero. (Antecedentes).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.1.</span> Los presentes términos y condiciones presuponen la
        existencia de un contrato de “Condiciones Generales” por el cual “Plexo” (referido aquí como PLEXO o
        el LICENCIANTE), presta servicios y permite a sus clientes (referidos aquí singularmente como USUARIO
        o LICENCIADO) utilizar y conectarse con la pasarela de pagos propiedad de “Plexo”.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.2.</span> PLEXO ha desarrollado una nueva funcionalidad sobre su
        plataforma de pagos digitales, denominada “OCA Links” (referido aquí como la FUNCIONALIDAD o como
        PLEXO LINKS).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>1.3.</span> Es intención de PLEXO licenciar PLEXO LINKS de manera
        gratuita a sus USUARIOS – condicionada a la vigencia del contrato referido en “1.1”, de acuerdo a los
        términos y condiciones que se establecen seguidamente.
      </Typography>
      <Typography variant="h5" component="h2">
        Segundo. (Objeto).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.1.</span> PLEXO concede y pone a disposición del USUARIO una licencia
        de uso gratuita, no exclusiva y revocable sobre la FUNCIONALIDAD PLEXO LINKS. Consecuentemente, el
        USUARIO queda autorizado al uso no exclusivo del software que permite la FUNCIONALIDAD y a recibir los
        servicios relacionados o vinculados con la referida FUNCIONLIADAD.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>2.2.</span> ​PLEXO LINKS se considera parte integrante de la plataforma
        de pagos propiedad de PLEXO, por tanto, todo lo no regulado específicamente en el presente documento
        será regido por las “Condiciones Generales” referidas en 1.1. La utilización de PLEXO LINKS no
        devengará un precio adicional o diferencial al ya establecido para el uso de la plataforma de pago,
        siendo por tanto sin costo para el USUARIO la utilización de la nueva FUNCIONALIDAD. Sin perjuicio de
        lo expresado, PLEXO queda facultado a establecer un precio adicional para el uso PLEXO LINKS, a tales
        efectos deberá cursar un pre-aviso al USUARIO de 30 días corridos antes de comenzar a devengarse el
        precio y en todos los casos el USUARIO podrá optar por cesar la utilización de PLEXO LINKS.
      </Typography>
      <Typography variant="h5" component="h2">
        Tercero. (Funcionamiento).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.1.</span> PLEXO LINKS es un software propiedad de PLEXO que otorga la
        funcionalidad al USUARIO de enviar a terceros determinados un link a partir del cual surge una suma
        específica a pagar en favor del USUARIO, utilizando la plataforma de pago propiedad de PLEXO, lo cual
        genera una simplificación en los procesos de pagos.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>3.2.</span> PLEXO no garantiza que PLEXO LINKS y sus procesos
        vinculados estén libres de errores, fallas o puedan ser objeto de “hackeo” por terceras partes. PLEXO
        únicamente garantiza mantener los estándares de seguridad de la industria de los medios de pago
        electrónicos, y que, en caso de detectarse una falla, error o “hackeo” realizará sus máximos esfuerzos
        para subsanarlo en el menor tiempo posible.
      </Typography>
      <Typography variant="h5" component="h2">
        Cuarto. (Finalidad – Utilización).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.1.</span> Los USUARIOS se obligan a utilizar PLEXO LINKS ajustándose
        a las regulaciones de los presentes términos y condiciones, las “Condiciones Generales” y únicamente
        para procesar operaciones y finalidades lícitas.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.2.</span> Cualquier uso indebido por parte del USUARIO dará derecho a
        que PLEXO pueda suspender el acceso a la plataforma de pago o a PLEXO LINKS de manera transitoria o
        definitiva rescindiendo los contratos que los vincula con el USUARIO, incluyendo la presente LICENCIA
        de uso.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.3.</span> El USUARIO deberá, ante la solicitud de PLEXO, remitir toda
        información pertinente y razonable sobre las operaciones que canaliza a través de PLEXO LINKS y la
        plataforma de pagos, así como colaborar con PLEXO ante caso de actos fraudulentos.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>4.4.</span> El USUARIO tiene prohibido utilizar PLEXO LINKS y la
        plataforma de pagos para canalizar operaciones por cuenta y orden de terceras entidades, debiendo
        utilizar los sistemas referidos únicamente para operaciones de pago propias.
      </Typography>
      <Typography variant="h5" component="h2">
        QUINTO. (Limitación de Responsabilidad).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.1.</span> PLEXO limita su responsabilidad por todo concepto a las
        sumas pagas por el USUARIO a PLEXO en los doce meses previos al hecho que dio lugar al nacimiento de
        la responsabilidad.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.2.</span> El USUARIO contrata y utiliza la plataforma de pago y el
        servicio “OCA Links” conociendo la limitación de responsabilidad y en el entendido que la referida
        limitación de responsabilidad tiene relación con el precio pagado y los riesgos asociados a la
        actividad.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>5.3.</span> El USUARIO mantendrá indemne y libre de daño a PLEXO, sus
        empleados, directores, representantes y proveedores, por cualquier pérdida, gasto, daño o costos –
        incluyendo honorarios de abogados – que sean consecuencia de un incumplimiento de los presentes
        términos y condiciones, ya sea de manera directa o a por terceros vinculados.
      </Typography>
      <Typography variant="h5" component="h2">
        SEXTO. (Disposiciones Varias).
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.1.</span> Las disposiciones del presente documento se consideran
        parte integrante de las “Condiciones Generales” por tanto aplicables tanto a PLEXO LINKS como
        aplicables en lo pertinente a los servicios provistos por la pasarela de pagos. En caso de
        contradicción entre ambos documentos, prevalecerá lo dispuesto en el presente.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.2.</span> La omisión en el ejercicio de cualquiera de los derechos
        derivados de este documento no configurará renuncia del mismo o de otros derechos derivados de la
        relación entre las partes.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.3.</span> ​La eventual nulidad, total o parcial, que pudiese
        declararse respecto de cualquiera de las cláusulas contenidas en el presente documento, no afectará ni
        influirá, de modo alguno, a las restantes estipulaciones que se mantendrán integralmente con el mismo
        vigor y fuerza jurídica.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.4.</span> Todos los contenidos de la FUNCIONALIDAD sean estos
        visibles, auditivos o aquellos que permiten el funcionamiento de la misma se encuentran protegidas por
        derechos de propiedad intelectual y son de exclusiva propiedad de PLEXO. A título enunciativo las
        gráficas, colores relacionados, diseño de interfaces, audios, logos, software, información y todo
        demás contenido que permite el funcionamiento del sitio web y sus aplicaciones para smartphones son de
        exclusiva propiedad de PLEXO; quedando prohibida su reproducción, copia o utilización no autorizada
        para cualquier finalidad.
      </Typography>
      <Typography className={classes.paragraph}>
        <span className={classes.item}>6.5.</span> Estos términos y condiciones podrán ser modificados por
        PLEXO, en cualquier momento, siempre que notifique a los USUARIOS sobre las modificaciones. Esta
        notificación, como cualquier otra notificación a la que refieran estos términos y condiciones, podrá
        realizarse por cualquier medio hábil que permita su correcta recepción por el USUARIO. Los nuevos
        términos y condiciones entraran en vigencia a los 3 días corridos desde la notificación.
      </Typography>
    </>
  );
}

export default TermsAndConditions;
