import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ScreenTitle } from './../../components/ScreenTitle';
import {
  Typography,
  Divider,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Hidden,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { SingleLink } from './../../components/Icons/SingleLink';
import { PaymentsTable } from '../../components/PaymentsTable';
import { SmallLinkCard } from '../../components/SmallLinkCard';
import { QuickLinkCard } from '../../components/QuickLinkCard';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { DeleteLinkRequest, Filters, RootState } from './../../store/config/types';
import { fetchPayments } from './../../store/action_creators/payments.actions';
import { AppActions } from './../../store/config/ActionTypes';
import { LinkDetailDialog } from '../../components/dialogs/LinkDetailDialog';
import { LinkTypeEnum, OrderFilterEnum, Status } from '../../store/config/enums';
import { deleteLink, fetchLinks } from '../../store/action_creators/links.actions';
import { Link as PlexoLink } from '../../store/config/types';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { QrCodeDialog } from '../../components/dialogs/QRCodeDialog';
import { PermanentLink } from '../../components/Icons/PermanentLink';
import { QuickLink } from '../../components/Icons/QuickLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstDivider: {
      margin: '1rem 0rem 2rem 0rem',
    },
    secondDivider: {
      margin: '1rem 0',
    },
    halfWidth: {
      width: '50%',
    },
    buttonsDiv: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    buttonSeparation: {
      width: '2rem',
    },
    button: {
      width: '50%',
      '& .MuiButton-startIcon': {
        color: '#ffffff',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '0.4rem',
      },
    },
    faIcon: {
      height: '1rem',
    },
    whiteButton: {
      whiteSpace: 'nowrap',
      backgroundColor: '#ffffff',
      borderColor: '#B1C9DE',
    },
    containerDiv: {
      '& > *': {
        marginTop: '4rem',
        [theme.breakpoints.down('md')]: {
          marginTop: '2rem',
        },
      },
    },
    linkList: {
      marginTop: '0',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'auto',
      },
    },
    quickLinkCard: {
      float: 'right',
    },
    paymentsTable: {
      marginTop: '2rem',
    },
  }),
);

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  payments: state.payments,
  links: state.links,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchPayments: (filters: Filters, page: number, limit: number, businessId: number) =>
    dispatch(fetchPayments(filters, page, limit, businessId)),
  fetchLinks: (filters: Filters, businessId: number, type: LinkTypeEnum, reset: boolean) =>
    dispatch(fetchLinks(filters, 1, 10, businessId, type, reset)),
  deleteLink: (deleteRequest: DeleteLinkRequest) => dispatch(deleteLink(deleteRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Dashboard({ auth, payments, links, fetchPayments, fetchLinks, deleteLink }: PropsFromRedux) {
  const [permaLinkOpen, setPermaLinkOpen] = useState<boolean>(false);
  const [oneTimeLinkOpen, setOneTimeLinkOpen] = useState<boolean>(false);
  const [openQrDialog, setOpeQrDialog] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingLink, setDeletingLink] = useState<boolean>(false);
  const [linkToDelete, setLinkToDelete] = useState<PlexoLink | null>(null);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const businessId = auth?.account?.business?.id ?? -1;
  const businessName = auth?.account?.business?.name ?? '';

  useEffect(() => {
    if (
      businessId !== -1 &&
      !payments.loadingPayments &&
      payments.payments === null &&
      payments.paymentsErrorMessage === null
    ) {
      fetchPayments(payments.filters, payments.page, payments.pageSize, businessId);
    }
  }, [
    payments.loadingPayments,
    payments.payments,
    payments.paymentsErrorMessage,
    payments.filters,
    fetchPayments,
    payments.page,
    payments.pageSize,
    auth.account,
    businessId,
  ]);

  useEffect(() => {
    if (
      auth.account &&
      !links.loadingLinks &&
      (links.links === null || links.linksType !== LinkTypeEnum.ONETIME) &&
      links.linksErrorMessage === null
    ) {
      fetchLinks(
        { order: OrderFilterEnum.DescendingDate },
        auth.account?.business?.id!,
        LinkTypeEnum.ONETIME,
        true,
      );
    }
  }, [
    links.loadingLinks,
    links.links,
    links.linksType,
    links.linksErrorMessage,
    fetchLinks,
    links.linksFilters,
    auth.account,
  ]);

  const beginDeleteLink = (link: PlexoLink) => {
    setDeleteOpen(true);
    setLinkToDelete(link);
  };

  const confirmDeleteLink = () => {
    setDeletingLink(true);

    if (auth.account?.business.id && linkToDelete) {
      const deleteLinkRequest: DeleteLinkRequest = {
        businessId: auth.account.business.id.toString(),
        linkId: linkToDelete.id,
      };
      deleteLink(deleteLinkRequest);
    }
  };

  const closeDeleteSnack = () => {
    setDeletingLink(false);
    if (links.deleteLinkSuccess) setDeleteOpen(false);
  };

  return (
    <>
      {auth.account && auth.account.business?.acceptsOnTheFly && (
        <Hidden mdDown>
          <QuickLinkCard
            className={classes.quickLinkCard}
            businessName={businessName}
            openQrDialog={setOpeQrDialog}
          />
        </Hidden>
      )}
      <div className="screen-container">
        <ScreenTitle title="Tablero" />
        <Divider className={classes.firstDivider} />
        <Typography variant="h5">Crear links</Typography>
        <Divider className={classes.secondDivider} />
        <div className={classes.buttonsDiv}>
          <Button
            disableElevation
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<PermanentLink color="#ffffff" />}
            onClick={() => setPermaLinkOpen(true)}
          >
            Link multiuso
          </Button>
          <div className={classes.buttonSeparation} />
          <Button
            disableElevation
            className={classes.button}
            color="primary"
            variant="contained"
            startIcon={<SingleLink color={'#ffffff'} />}
            onClick={() => setOneTimeLinkOpen(true)}
          >
            Link de 1 uso
          </Button>
          {isMobile && (
            <Button
              disableElevation
              className={classes.button}
              color="primary"
              variant="contained"
              startIcon={<QuickLink color={'#ffffff'} />}
              onClick={() => setOpeQrDialog(true)}
            >
              Pagos rápidos
            </Button>
          )}
        </div>
        <div className={classes.containerDiv}>
          <div>
            <div className="flex-row">
              <Typography variant="h5">Pagos</Typography>
              <Button
                variant="outlined"
                className={classes.whiteButton}
                onClick={() => history.push('/payments')}
              >
                Ver todos
              </Button>
            </div>
            <div className={classes.paymentsTable}>
              <PaymentsTable maxItems={5} />
            </div>
          </div>
          <div>
            <div className="flex-row">
              <Typography variant="h5">Links de 1 uso</Typography>
              <Button
                variant="outlined"
                className={classes.whiteButton}
                onClick={() => history.push('/one-use-links')}
              >
                Ver todos
              </Button>
            </div>
            <Grid container spacing={2} className={`${classes.linkList} links-list`}>
              {links.links &&
                links.links.slice(0, 4).map((link) => (
                  <Grid item xs={12} sm={6} key={link.id}>
                    <SmallLinkCard link={link} setLinkToDelete={beginDeleteLink} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </div>
      <LinkDetailDialog
        open={permaLinkOpen}
        setOpen={setPermaLinkOpen}
        link={null}
        linkType={LinkTypeEnum.PERMANENT}
      />
      <LinkDetailDialog
        open={oneTimeLinkOpen}
        setOpen={setOneTimeLinkOpen}
        link={null}
        linkType={LinkTypeEnum.ONETIME}
      />
      <QrCodeDialog
        open={openQrDialog}
        setOpen={setOpeQrDialog}
        url={`${window.location.origin}/${businessName}`}
        description={
          auth?.account?.business.name ? `Pagos rápidos de ${auth?.account?.business.name}` : 'Código QR'
        }
      />
      <DeleteDialog
        message="¿Está seguro de que desea eliminar el link?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteAction={confirmDeleteLink}
        deleteDisabled={deletingLink}
      />
      <CustomSnackbar
        open={deletingLink && (links.deleteLinkSuccess || links.deleteLinkErrorMessage !== null)}
        message={
          links.deleteLinkSuccess ? 'Se borró el link correctamente' : 'Ocurrió un error al borrar el link'
        }
        handleClose={closeDeleteSnack}
        type={links.deleteLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default connector(Dashboard);
