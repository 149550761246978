import React, { useState, useEffect, ChangeEvent } from 'react';
import { ScreenTitle } from '../../components/ScreenTitle';
import { Typography, Switch, Link, Button, Paper } from '@material-ui/core';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FormTextField } from '../../components/forms/FormTextField';
import { dateHelper } from '../../helpers/dateHelper';
import { RootState, UpdateBusinessRequest } from '../../store/config/types';
import { ThunkDispatch } from 'redux-thunk';
import { AppActions } from '../../store/config/ActionTypes';
import { updateBusiness } from '../../store/action_creators/business.actions';
import { connect, ConnectedProps } from 'react-redux';
import { EmailConfirmationDialog } from '../../components/dialogs/EmailConfirmationDialog';
import { ClientSecret } from '../../components/ClientSecret';
import { Loading } from '../../components/Loading';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { Status } from '../../store/config/enums';

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  business: state.business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  updateBusiness: (updateBusinessRequest: UpdateBusinessRequest) =>
    dispatch(updateBusiness(updateBusinessRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Values {
  email: string;
  clientId: string;
  url: string;
}

function Configuration({ auth, business, updateBusiness }: PropsFromRedux) {
  const [updating, setUpdating] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [fastPaymentsEnabled, setFastPaymentsEnabled] = useState<boolean>(false);
  const [emailsEnabled, setEmailsEnabled] = useState<boolean>(false);
  const [emailConfirmationOpen, setEmailConfirmationOpen] = useState<boolean>(false);
  const [emailConfirmationConfirmed, setEmailConfirmationConfirmed] = useState<boolean>(false);
  const [secret, setSecret] = useState<string>('');

  useEffect(() => {
    if (!initialized && auth.account) {
      setInitialized(true);
      setFastPaymentsEnabled(auth.account.business.acceptsOnTheFly ?? false);
      setEmailsEnabled(auth.account.business.mailNotificationsEnabled ?? false);
      setSecret(auth.account.business.clientSecret ?? '');
    }
  }, [initialized, auth.account]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFastPaymentsEnabled(event.target.checked);
  };

  const handleEmailSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailsEnabled(event.target.checked);
  };

  const emailConfirmation = () => {
    setEmailConfirmationOpen(false);
    setEmailConfirmationConfirmed(true);
  };

  const submitBusiness = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      if (emailsEnabled && !emailConfirmationConfirmed) {
        setEmailConfirmationOpen(true);
      } else {
        setEmailConfirmationConfirmed(false);
        setUpdating(true);

        const updatedBusiness: UpdateBusinessRequest = {
          businessId: auth.account.business.id!,
          name: auth.account.business.name,
          legalId: auth.account.business.legalId,
          acceptsOnTheFly: fastPaymentsEnabled,
          softDescriptor: auth.account.business.softDescriptor,
          vatRate: auth.account.business.vatRate!,
          sendNotification: emailsEnabled,
          mailForNotifications: values.email,
          urlForSystemNotifications: values.url,
          clientId: values.clientId,
          clientSecret: secret,
        };

        updateBusiness(updatedBusiness);
      }
    }
  };

  const closeSnack = () => {
    setUpdating(false);
  };

  return (
    <div className="screen-container configuration">
      <ScreenTitle title="Configuración" />
      {auth.account ? (
        <Formik
          initialValues={{
            email: auth.account.business.mailForNotifications,
            clientId: auth.account.business.clientId,
            url: auth.account.business.urlForSystemNotifications,
          }}
          onSubmit={submitBusiness}
        >
          <Form className="form">
            <Paper elevation={3}>
              <div className="business-config">
                <Typography variant="body1">Habilitar pagos rápidos</Typography>
                <Switch color="secondary" checked={fastPaymentsEnabled} onChange={handleSwitchChange} />
              </div>
            </Paper>
            <Paper elevation={3}>
              <div className="business-config">
                <Typography variant="body1">
                  Habilitar envío de correos{' '}
                  {!emailsEnabled && auth.account.business.notificateUntil && (
                    <Typography variant="caption">
                      (habilitado hasta{' '}
                      {dateHelper.toEvenShorterNiceString(auth.account.business.notificateUntil)})
                    </Typography>
                  )}
                </Typography>
                <Switch color="secondary" checked={emailsEnabled} onChange={handleEmailSwitchChange} />
                <EmailConfirmationDialog
                  message="Recuerda que las notificaciones por correo tienen un costo mensual."
                  open={emailConfirmationOpen}
                  setOpen={setEmailConfirmationOpen}
                  action={emailConfirmation}
                />
              </div>
              {emailsEnabled && (
                <>
                  <div className="business-config">
                    <Field
                      name="email"
                      component={FormTextField}
                      type="text"
                      placeholder="Correo del destinatario"
                      style={{ marginTop: '1rem' }}
                    />
                  </div>
                  <Typography variant="subtitle2" style={{ margin: '0.6rem 0 0.6rem' }}>
                    Este servicio tiene un costo mensual asociado. Para conocer más acerca de las condiciones
                    del servicio, haga <Link>clic aquí</Link>.
                  </Typography>
                </>
              )}
            </Paper>
            <Typography variant="h5">Configuración avanzada</Typography>
            <Paper elevation={2} style={{ paddingTop: '1.6rem' }}>
              <Field name="clientId" component={FormTextField} disabled type="text" placeholder="Client ID" />
              <ClientSecret secret={secret} setSecret={setSecret} />
              <Field
                name="url"
                component={FormTextField}
                type="text"
                placeholder="URL para notificación de pagos"
              />
            </Paper>
            <Button type="submit" color="primary" variant="contained" fullWidth style={{ marginTop: '1rem' }}>
              Guardar cambios
            </Button>
          </Form>
        </Formik>
      ) : (
        <Loading />
      )}
      <CustomSnackbar
        open={updating && (business.updateBusinessSuccess || business.updateBusinessErrorMessage !== null)}
        message={
          business.updateBusinessSuccess
            ? 'Se actualizó la empresa correctamente'
            : business.updateBusinessErrorMessage!
        }
        handleClose={closeSnack}
        type={business.updateBusinessSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default connector(Configuration);
